/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliverableService } from '@app/services';

@Component({
  selector: 'app-deliverable-review-request-dialog',
  templateUrl: 'deliverable-review-request-dialog.html',
  // styleUrls: ['./deliverable-dialog.components.scss']
})
export class ReviewRequestDialog implements OnInit {
  loading: any = 0;
  reviewers: Array<any> = [];
  requestForm: UntypedFormGroup;
  submitted: any = false;
  constructor(
    public dialogRef: MatDialogRef<ReviewRequestDialog>,
    @Inject(MAT_DIALOG_DATA) public deliverable: any,
    private deliverableService: DeliverableService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this._getReviewers();
    this.requestForm = this.formBuilder.group({
      recipient: [null, Validators.required],
    });
  }

  _getReviewers(): void {
    this.loading++;
    this.deliverableService.getReviewers(this.deliverable._id)
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.reviewers = response.result;
        }
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSendRequest(): void {
    this.submitted = true;
    if (this.requestForm.valid && !this.loading) {
      this.loading++;
      const requestFormData = this.requestForm.getRawValue();
      this.deliverableService.reviewRequest(this.deliverable._id, requestFormData.recipient)
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.dialogRef.close(true);
          }
        });
    }
  }
}

@Component({
  selector: 'app-deliverable-reassign-dialog',
  templateUrl: 'deliverable-reassign-dialog.html',
  // styleUrls: ['./deliverable-dialog.components.scss']
})
export class ReassignDialog implements OnInit {
  loading: any = 0;
  step: any = 1;
  reviewers: Array<any> = [];
  reassignForm: UntypedFormGroup;
  submitted: any = false;
  public inputComments: any = '';
  constructor(
    public dialogRef: MatDialogRef<ReassignDialog>,
    @Inject(MAT_DIALOG_DATA) public deliverable: any,
    private deliverableService: DeliverableService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this._getReviewers();
    this.reassignForm = this.formBuilder.group({
      reason: ['CHANGE', Validators.required],
      recipient: [null],
      comments: [null, Validators.required],
    });
  }

  _getReviewers(): void {
    this.loading++;
    this.deliverableService.getReviewers(this.deliverable._id)
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.reviewers = response.result;
        }
      });
  }

  onNext(): void {
    this.submitted = true;
    if (this.step === 1) {
      if (this.reassignForm.get('reason').valid) {
        this.submitted = false;
        if (this.reassignForm.get('reason').value == 'CHANGE') {
          this.step = 3;
        } else {
          this.step++;
        }
      }
    } else if (this.step === 2) {
      if (this.reassignForm.get('recipient').value) {
        this.submitted = false;
        this.step++;
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onReassign(): void {
    this.submitted = true;
    if (this.reassignForm.valid && !this.loading) {
      this.loading++;
      if (this.reassignForm.get('reason').value == 'CHANGE') {
        this.deliverableService.approveRework(this.deliverable._id, this.reassignForm.getRawValue())
          .subscribe((response) => {
            this.loading--;
            if (response) {
              this.dialogRef.close(true);
            }
          });
      } else {
        this.deliverableService.approveReassign(this.deliverable._id, this.reassignForm.getRawValue())
          .subscribe((response) => {
            this.loading--;
            if (response) {
              this.dialogRef.close(true);
            }
          });
      }
    }
  }

  changeCommentValue(value) {
    this.inputComments = value;
  }
}


@Component({
  selector: 'app-deliverable-approve-dialog',
  templateUrl: 'deliverable-approve-dialog.html',
  // styleUrls: ['./deliverable-dialog.components.scss']
})
export class ApproveDialog {
  loading: any = 0;
  constructor(
    public dialogRef: MatDialogRef<ApproveDialog>,
    private deliverableService: DeliverableService,
    @Inject(MAT_DIALOG_DATA) public deliverable: any,
  ) { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onApprove(): void {
    if (!this.loading) {
      this.loading++;
      this.deliverableService.approve(this.deliverable._id)
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.dialogRef.close(true);
          }
        });
    }
  }
}

@Component({
  selector: 'app-deliverable-publish-request-dialog',
  templateUrl: 'deliverable-publish-request-dialog.html',
  // styleUrls: ['./deliverable-dialog.components.scss']
})
export class PublishRequestDialog implements OnInit {
  loading: any = 0;
  inComplete: Array<any> = [];
  publishApprovers: any = '';
  constructor(
    public dialogRef: MatDialogRef<PublishRequestDialog>,
    @Inject(MAT_DIALOG_DATA) public deliverable: any,
    private deliverableService: DeliverableService,
  ) { }

  ngOnInit(): void {
    if (this.deliverable.primary_location) {
      const locationLeads = this.deliverable.primary_location.location_lead.map((o) => o.user_id);
      for (const i in locationLeads) {
        if (Number(i) === 0 || locationLeads.length === 1) {
          this.publishApprovers = locationLeads[i];
        } else {
          this.publishApprovers = this.publishApprovers + '; ' + locationLeads[i];
        }
      }
    }
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  onSendRequest(): void {
    if (!this.loading) {
      this.loading++;
      this.deliverableService.publishRequest(this.deliverable._id)
        .subscribe((response) => {
          this.loading--;
          if (response && response.message && response.message instanceof Array) {
            this.inComplete = response.message;
          } else {
            this.dialogRef.close(true);
          }
        });
    }
  }
}

@Component({
  selector: 'app-deliverable-rework-dialog',
  templateUrl: 'deliverable-rework-dialog.html',
  // styleUrls: ['./deliverable-dialog.components.scss']
})
export class ReworkDialog implements OnInit {
  loading: any = 0;
  reworkForm: UntypedFormGroup;
  submitted: any = false;
  public inputComments: any = '';
  constructor(
    public dialogRef: MatDialogRef<ReassignDialog>,
    @Inject(MAT_DIALOG_DATA) public deliverable: any,
    private deliverableService: DeliverableService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.reworkForm = this.formBuilder.group({
      comments: [null, Validators.required],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onRework(): void {
    this.submitted = true;
    if (this.reworkForm.valid && !this.loading) {
      this.loading++;
      this.deliverableService.publishRework(this.deliverable._id, this.reworkForm.getRawValue())
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.dialogRef.close(true);
          }
        });
    }
  }

  changeCommentValue(value) {
    this.inputComments = value;
  }
}

@Component({
  selector: 'app-deliverable-publish-dialog',
  templateUrl: 'deliverable-publish-dialog.html',
  // styleUrls: ['./deliverable-dialog.components.scss']
})
export class PublishDialog {
  loading: any = 0;
  published: any = false;
  constructor(
    private deliverableService: DeliverableService,
    public dialogRef: MatDialogRef<PublishDialog>,
    @Inject(MAT_DIALOG_DATA) public deliverable: any,
  ) { this.dialogRef.disableClose = true; }

  
  onCancel(): void {
    this.dialogRef.close();
  }

  onPublish(): void {
    this.published = true;
    if (!this.loading) {
      this.loading++;
      this.deliverableService.publishApprove(this.deliverable._id)
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.published = true;
          }
        });
    }
  }

  onOk(): void {
    this.dialogRef.close(true);
  }
}


@Component({
  selector: 'app-deliverable-comments-dialog',
  templateUrl: 'deliverable-comments-dialog.html',
  // styleUrls: ['./deliverable-dialog.components.scss']
})
export class DeliverableCommentsDialog {
  loading: any = 0;
  published: any = false;
  constructor(
    public dialogRef: MatDialogRef<PublishDialog>,
    @Inject(MAT_DIALOG_DATA) public comments: Array<any>,
  ) {
  }


  onCancel(): void {
    this.dialogRef.close();
  }
}