/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoriesService } from '@app/services';


@Component({
  selector: 'app-story-publish-request-dialog',
  templateUrl: 'story-publish-request-dialog.html',
  // styleUrls: ['./story-dialog.components.scss']
})
export class StoryPublishRequestDialog implements OnInit {
  loading: any = 0;
  inComplete: Array<any> = [];
  publishApprovers: any = '';
  constructor(
    public dialogRef: MatDialogRef<StoryPublishRequestDialog>,
    @Inject(MAT_DIALOG_DATA) public story: any,
    private storiesService: StoriesService,
  ) { }

  ngOnInit(): void {
    if (this.story.primary_location) {
      const locationLeads = this.story.primary_location.location_lead.map((o) => o.user_id);
      for (const i in locationLeads) {
        if (Number(i) === 0 || locationLeads.length === 1) {
          this.publishApprovers = locationLeads[i];
        } else {
          this.publishApprovers = this.publishApprovers + '; ' + locationLeads[i];
        }
      }
    }
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  onSendRequest(): void {
    if (!this.loading) {
      this.loading++;
      this.storiesService.publishRequest(this.story._id)
        .subscribe((response) => {
          this.loading--;
          if (response && response.message && response.message instanceof Array) {
            this.inComplete = response.message;
          } else {
            this.dialogRef.close(true);
          }
        });
    }
  }
}

@Component({
  selector: 'app-story-publish-dialog',
  templateUrl: 'story-publish-dialog.html',
  // styleUrls: ['./story-dialog.components.scss']
})
export class StoryPublishDialog {
  loading: any = 0;
  published: any = false;
  constructor(
    private storiesService: StoriesService,
    public dialogRef: MatDialogRef<StoryPublishDialog>,
    @Inject(MAT_DIALOG_DATA) public story: any,
  ) { this.dialogRef.disableClose = true; }


  onCancel(): void {
    this.dialogRef.close();
  }

  onPublish(): void {
    this.published = true;
    if (!this.loading) {
      this.loading++;
      this.storiesService.publishApprove(this.story._id)
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.published = true;
          }
        });
    }
  }

  onOk(): void {
    this.dialogRef.close(true);
  }
}

@Component({
  selector: 'app-story-rework-dialog',
  templateUrl: 'story-rework-dialog.html',
  // styleUrls: ['./story-dialog.components.scss']
})
export class ReworkStoryDialog implements OnInit {
  loading: any = 0;
  reworkForm: UntypedFormGroup;
  submitted: any = false;
  public inputComments: any = '';
  constructor(
    public dialogRef: MatDialogRef<ReworkStoryDialog>,
    @Inject(MAT_DIALOG_DATA) public story: any,
    private storiesService: StoriesService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.reworkForm = this.formBuilder.group({
      comments: [null, Validators.required],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onRework(): void {
    this.submitted = true;
    if (this.reworkForm.valid && !this.loading) {
      this.loading++;
      this.storiesService.publishRework(this.story._id, this.reworkForm.getRawValue())
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.dialogRef.close(true);
          }
        });
    }
  }

  changeCommentValue(value) {
    this.inputComments = value;
  }
}

@Component({
  selector: 'app-story-comments-dialog',
  templateUrl: 'story-comments-dialog.html',
  // styleUrls: ['./story-dialog.components.scss']
})
export class StoryCommentsDialog {
  loading: any = 0;
  published: any = false;
  constructor(
    public dialogRef: MatDialogRef<StoryCommentsDialog>,
    @Inject(MAT_DIALOG_DATA) public comments: Array<any>,
  ) {
  }


  onCancel(): void {
    this.dialogRef.close();
  }
}