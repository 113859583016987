<div fxLayout="column" class="deliverable-dialog">
    <div fxHide [fxShow]="!published" fxLayout="column">
        <h2 id="publishByAll" class="dialog-title">Publish Success Stories Page</h2>
        <div fxLayout="column" class="dialog-content">
            <p id="publishByAllText">Publishing this page will make it available to view by all Accenture from Access
                Innovation. Are you sure you want to publish?</p>
        </div>
        <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
            <button id="cancel" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
            <button id="publish" mat-flat-button type="button" color="primary" (click)="onPublish()" cdkFocusInitial>Publish</button>
        </div>
    </div>
    <div fxHide [fxShow]="published" fxLayout="column">
        <h2 id="publishADay" class="dialog-title">Success Stories Page Published</h2>
        <div fxLayout="column" class="dialog-content">
            <p id="publishADayText">This success story is now published in Access Innovation. <br /> If you cannot
                find your success story in Access Innovation, please contact us</p>
        </div>
        <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
            <button id="okay" mat-flat-button type="button" color="primary" (click)="onOk()" cdkFocusInitial>Okay</button>
        </div>
    </div>
</div>