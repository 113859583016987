<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>

<style>
    /* Welcome title */
    .header-top {
        margin: -8px -8px auto;
        font-weight: 600;
        line-height: 35px;
        padding-left: 25.5%;
        padding-right: 15px;
        padding-top: 0.5%;
        color: white;
        font-size: 15px;
        background: #480474;
    }

    @media only screen and (max-width: 767px) {
        .header-top {
            line-height: 45px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    /* header background img: */
    .bg-header {
        background: #fff url('../../../assets/images/a3e36062faadccbd4f7d45890347ed51.jpeg') no-repeat;
        height: 62px;
        width: 100%;
        border-bottom: 1px solid #f7f7f7;
        background-size: 630px;
        background-position: center 100%;
    }

    @media only screen and(max-width: 767px) {
        .bg-header {
            background: #fff url('../../../assets/images/a3e36062faadccbd4f7d45890347ed51.jpeg') no-repeat;
            background-position: center 50%;
        }
    }

    .survey-header {
                    border: 1px solid #480474;
                    background-color: #480474;
                    color: white;
                    border-radius: 6px;
                    box-shadow: 5px 5px rgb(164 164 164 / 80%);
                    width: 50%;
                    margin-top: 1% !important;
                    margin-bottom: 1% !important;
                    margin: auto;
                }
</style>

<body>
<div class="header-top">
  Innovation
</div>

<div class="bg-header"></div>
<div class="survey-header container"> 
    <div class="row">
        <div class="col-sm-2 col-md-2 col-lg-2">
        <img [src]=demoURL alt="survey-image" style="margin-top: -2px;">
        </div>
        <div class="col-sm-10 col-md-10 col-lg-10">
            <p style="margin-top: 1.8%">
                <b>Announcement:</b>  The Demos platform has been decommissioned as of February 28, 2022.  If you had critical content that you did not extract prior to decommissioning, please contact us.<br><br> To view Technology Innovation content (Labs, Incubation/XR and Liquid Studios produced), please visit ScaleInnovation to view their innovation activities and creations at <a href="https://scaleinnovation.accenture.com/home" style="color:#a104fa; text-decoration: underline;">https://scaleinnovation.accenture.com/home</a>.
            </p>
        </div>
    </div>
</div>
</body>
</html>


